import React, { useEffect, useContext } from "react"

import AreaCalcContext from "../context/area-calc/area-calc-context"

import SEO from "../components/theme/header/seo"
import Layout from "../components/theme/layout"
import AreaCalcPopup from "../components/popups/area-calc-popup"

// ======================
//        COMPONENT
// ======================
const AreaCalcPage = ({ pageContext, location }) => {
  // NEVER use setAreaCalcPopupOpen to open the popup!! Only use openAreaCalcPopup
  // it is only used here to catch an error
  const { setAreaCalcPopupOpen } = useContext(AreaCalcContext)

  const title = "Area Calculator"

  useEffect(() => {
    setAreaCalcPopupOpen(true)
  }, [])

  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO title={title} location={location} yoast={{}} />
      <AreaCalcPopup isAreaCalcPage location={location} />
    </Layout>
  )
}

export default AreaCalcPage
